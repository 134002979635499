/**
 * v0 by Vercel.
 * @see https://v0.dev/t/bz8QpogGrc1
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */

import { SingleDocResponseData } from "~/api/query_fns/documents";
import { StepReportComponent } from "../components/StepReportComponent";
import AdditionalInformation from "~/components/AdditionalInformation";
import { Card } from "~/components/ui/card";
import UploadArea from "./Documents/UploadArea";
import { useGenerateReportNew } from "~/hooks/useGenerateReportNew";
import { LoadingComponent } from "~/utils/util-report";
import { ReportType } from "~/api/query_fns/coverage-analysis";
import { DocumentTypeEnum } from "~/api/query_fns/documents";
import { useState, useEffect } from "react";

export default function UCCoverageCheckListNew() {
  const {
    isLoading,
    handleGenerateReport,
    additionalNotes,
    setAdditionalNotes,
    handleUploadComplete,
    handleDeleteUploadedDoc,
    uploadedDocs,
  } = useGenerateReportNew("coverage_checklist" as ReportType);

  const [validationState, setValidationState] = useState({
    hasPolicyDoc: false,
    hasSpecSheet: false,
  });

  useEffect(() => {
    const policyDocs = uploadedDocs.filter(
      (doc) => doc.document.documentType === DocumentTypeEnum.Policy
    );
    const specDocs = uploadedDocs.filter(
      (doc) => doc.document.documentType === DocumentTypeEnum.Spec
    );

    setValidationState({
      hasPolicyDoc: policyDocs.length != 0,
      hasSpecSheet: specDocs.length != 0,
    });
  }, [uploadedDocs]);

  if (isLoading) {
    return <LoadingComponent title="GENERATING COVERAGE ANALYSIS" />;
  }

  return (
    <StepReportComponent
      title="Coverage Checklist"
      lastStepName="Generate Coverage Checklist"
      steps={[
        {
          number: 1,
          label: "Policy Documents",
          description: "Upload a single policy document.",
          component: (
            <PolicyDocumentsComponent
              uploadedDocs={uploadedDocs}
              handleUploadComplete={handleUploadComplete}
              handleDeleteUploadedDoc={handleDeleteUploadedDoc}
            />
          ),
          validate: () => validationState.hasPolicyDoc,
          getValidationMessages: () => [
            "Please upload exactly one policy document",
          ],
        },
        {
          number: 2,
          label: "Spec Sheet",
          description:
            "Upload a Spec Sheet/Checklist document in Excel Format. Contains a list of specs, for examples coverages, which must be checked against the policy. Must be in Excel format.",
          component: (
            <SpecSheetComponent
              uploadedDocs={uploadedDocs}
              additionalNotes={additionalNotes}
              setAdditionalNotes={setAdditionalNotes}
              handleUploadComplete={handleUploadComplete}
              handleDeleteUploadedDoc={handleDeleteUploadedDoc}
            />
          ),
          validate: () => validationState.hasSpecSheet,
          getValidationMessages: () => [
            "Please upload exactly one spec sheet in Excel format",
          ],
        },
      ]}
      onFinalStep={handleGenerateReport}
      onCancelPath="/coverage-checklist"
    />
  );
}

const PolicyDocumentsComponent = ({
  uploadedDocs,
  handleUploadComplete,
  handleDeleteUploadedDoc,
}: {
  uploadedDocs: SingleDocResponseData[];
  handleUploadComplete: (docResponse: SingleDocResponseData) => void;
  handleDeleteUploadedDoc: (docId: string) => void;
}) => {
  const policyDocs = uploadedDocs.filter(
    (doc) => doc.document.documentType === DocumentTypeEnum.Policy
  );

  return (
    <div id="step1" className="flex w-full flex-col">
      <Card className="p-3">
        <UploadArea
          uploadedDocs={policyDocs}
          db_document_type={DocumentTypeEnum.Policy}
          onUploadComplete={handleUploadComplete}
          onDeleteDocument={handleDeleteUploadedDoc}
          maxFiles={30}
          validateFiles={() => policyDocs.length === 1}
        />
      </Card>
    </div>
  );
};

const AdditionalInformationComponent = ({
  additionalNotes,
  setAdditionalNotes,
  handleUploadComplete,
  handleDeleteUploadedDoc,
}: {
  additionalNotes: string;
  setAdditionalNotes: (notes: string) => void;
  handleUploadComplete: (docResponse: SingleDocResponseData) => void;
  handleDeleteUploadedDoc: (docId: string) => void;
}) => {
  return (
    <AdditionalInformation
      additionalNotes={additionalNotes}
      setAdditionalNotes={setAdditionalNotes}
      handleUploadComplete={handleUploadComplete}
      handleDeleteUploadedDoc={handleDeleteUploadedDoc}
      placeholder="Please describe in detail which columns need to be filled in the Spec Sheet."
    />
  );
};

const SpecSheetComponent = ({
  uploadedDocs,
  additionalNotes,
  setAdditionalNotes,
  handleUploadComplete,
  handleDeleteUploadedDoc,
}: {
  uploadedDocs: SingleDocResponseData[];
  additionalNotes: string;
  setAdditionalNotes: (notes: string) => void;
  handleUploadComplete: (docResponse: SingleDocResponseData) => void;
  handleDeleteUploadedDoc: (docId: string) => void;
}) => {
  const specDocs = uploadedDocs.filter(
    (doc) => doc.document.documentType === DocumentTypeEnum.Spec
  );

  return (
    <div id="step2" className="flex w-full flex-col">
      <Card className="p-3">
        <UploadArea
          uploadedDocs={specDocs}
          db_document_type={DocumentTypeEnum.Spec}
          onUploadComplete={handleUploadComplete}
          onDeleteDocument={handleDeleteUploadedDoc}
          description="Upload a single spec sheet in excel format."
          allowedFileFormats={{
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
              [".xlsx", ".XLSX"],
          }}
          maxFiles={1}
          validateFiles={() => specDocs.length === 1}
        />
        <AdditionalInformationComponent
          additionalNotes={additionalNotes}
          setAdditionalNotes={setAdditionalNotes}
          handleUploadComplete={handleUploadComplete}
          handleDeleteUploadedDoc={handleDeleteUploadedDoc}
        />
      </Card>
    </div>
  );
};
