import React from "react";
import { createDocument, updateDocumentType, DocumentTypeEnum } from "~/api"; // Assuming updateDocumentType is an API function to update the document type in the database
import { useDropzone } from "react-dropzone";
import { useState, useEffect } from "react";
import { Progress } from "~/components/ui/progress";
import { CheckCircledIcon, Cross2Icon } from "@radix-ui/react-icons";
import { useQueryClient } from "react-query";
import { SingleDocResponseData } from "~/api/query_fns/documents";
import JobReportFileSearch from "~/components/JobReportFileSearch";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select";
import toast from "react-hot-toast";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "~/components/ui/dialog";
import { Button } from "~/components/ui/button"; // Adjust the import path as necessary
import { generateHash } from "~/utils";
import { DocumentTypeIcons } from "~/api/query_fns/documents";

// Add this near the top of the file with other type definitions
type UploadedFile = {
  filename: string;
  status?: "accepted" | "rejected";
  message?: string;
  ui_document_type: DocumentTypeEnum;
  docResponse: SingleDocResponseData;
};

// Update the DocumentType definition

const DocumentTypes: {
  [K in DocumentTypeEnum]: { label: string; value: DocumentTypeEnum };
} = {
  [DocumentTypeEnum.Policy]: {
    label: "Policy",
    value: DocumentTypeEnum.Policy,
  },
  [DocumentTypeEnum.Quote]: { label: "Quote", value: DocumentTypeEnum.Quote },
  [DocumentTypeEnum.Reference]: {
    label: "Select Document Type",
    value: DocumentTypeEnum.Reference,
  },
  [DocumentTypeEnum.AdditionalNotes]: {
    label: "Additional Notes",
    value: DocumentTypeEnum.AdditionalNotes,
  },
  [DocumentTypeEnum.Spec]: { label: "Spec", value: DocumentTypeEnum.Spec },
  [DocumentTypeEnum.Binder]: {
    label: "Binder",
    value: DocumentTypeEnum.Binder,
  },
  [DocumentTypeEnum.SingleEndorsement]: {
    label: "Single Endorsement",
    value: DocumentTypeEnum.SingleEndorsement,
  },
  [DocumentTypeEnum.Attachment]: {
    label: "Other",
    value: DocumentTypeEnum.Attachment,
  },
  [DocumentTypeEnum.Fact]: { label: "Fact", value: DocumentTypeEnum.Fact },
};

const MAX_FILE_SIZE = 150 * 1024 * 1024; // 150 MB in bytes

// Define a type for the file format object
type FileFormats = {
  [mimeType: string]: string[];
};

const RESTRICTED_DOCUMENT_TYPES = [
  DocumentTypeEnum.Policy,
  DocumentTypeEnum.Quote,
  DocumentTypeEnum.Binder,
  DocumentTypeEnum.SingleEndorsement,
  DocumentTypeEnum.Attachment, // This is "Other" in the UI
] as const;

// Default file formats
const DEFAULT_FILE_FORMATS: FileFormats = {
  "application/pdf": [".pdf", ".PDF"],
  "application/vnd.ms-excel": [".xls", ".XLS"],
  "image/jpeg": [".jpeg", ".jpg", ".JPEG", ".JPG"],
  "image/png": [".png", ".PNG"],
  "image/tiff": [".tiff", ".tif", ".TIFF", ".TIF"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
    ".XLSX",
  ],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
    ".DOCX",
  ],
};

// Add this new component before the UploadArea component
const FileListItem = ({
  file,
  onCategoryChange,
  onDeleteClick,
  availableDocumentTypes,
}: {
  file: UploadedFile;
  onCategoryChange: (filename: string, newType: DocumentTypeEnum) => void;
  onDeleteClick: (file: UploadedFile) => void;
  availableDocumentTypes: DocumentTypeEnum[];
}) => (
  <div
    className={`rounded-lg border ${
      file.status === "accepted"
        ? "border-emerald-100 bg-emerald-50"
        : "border-red-100 bg-red-50"
    } p-4 shadow-sm transition-all duration-300 hover:shadow-md`}
  >
    <div className="flex flex-col space-y-4">
      <div className="flex items-start justify-between space-x-4">
        <div className="flex min-w-0 items-start space-x-4">
          <div
            className={`flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full ${
              file.status === "accepted"
                ? "bg-emerald-100 text-emerald-600"
                : "bg-red-100 text-red-600"
            }`}
          >
            {file.status === "accepted" ? (
              <CheckCircledIcon className="h-7 w-7" />
            ) : (
              <Cross2Icon className="h-7 w-7" />
            )}
          </div>

          <div className="flex min-w-0 flex-1 flex-col space-y-1">
            <span className="text-md truncate font-medium text-gray-900">
              {file.filename}
            </span>
            {file.message && (
              <span className="text-sm text-gray-500">{file.message}</span>
            )}
          </div>
        </div>

        {file.status === "accepted" && (
          <div className="flex flex-shrink-0 items-center space-x-3">
            <Select
              value={
                file.docResponse.document.documentType || file.ui_document_type
              }
              onValueChange={(value) => {
                onCategoryChange(file.filename, value as DocumentTypeEnum);
              }}
              disabled={availableDocumentTypes.length === 1}
            >
              <SelectTrigger className="h-10 w-[220px] border-gray-200 bg-white shadow-sm transition-all hover:border-gray-300 hover:bg-gray-50">
                <SelectValue placeholder="Select Document Type">
                  {(file.ui_document_type ||
                    file.docResponse.document.documentType) && (
                    <div className="flex items-center gap-2">
                      {React.createElement(
                        DocumentTypeIcons[
                          file.ui_document_type ||
                            file.docResponse.document.documentType
                        ],
                        {
                          size: 18,
                          className: "text-gray-600",
                        }
                      )}
                      <span>
                        {
                          DocumentTypes[
                            file.ui_document_type ||
                              file.docResponse.document.documentType
                          ].label
                        }
                      </span>
                    </div>
                  )}
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                {availableDocumentTypes.map((documentTypeEnum) => {
                  const Icon = DocumentTypeIcons[documentTypeEnum];
                  return (
                    <SelectItem
                      key={documentTypeEnum}
                      value={documentTypeEnum}
                      className="flex items-center gap-2 py-2"
                    >
                      <div className="flex items-center gap-2">
                        <Icon size={18} className="text-gray-600" />
                        <span>{DocumentTypes[documentTypeEnum].label}</span>
                      </div>
                    </SelectItem>
                  );
                })}
              </SelectContent>
            </Select>

            <button
              onClick={() => onDeleteClick(file)}
              className="flex h-10 items-center justify-center rounded-md border border-gray-200 bg-white px-5 text-sm font-medium text-gray-700 transition-all hover:border-gray-300 hover:bg-gray-50"
            >
              <Cross2Icon className="mr-2 h-4 w-4" />
              Remove
            </button>
          </div>
        )}
      </div>
    </div>
  </div>
);

// Add onValidationChange to the props
interface UploadAreaProps {
  uploadedDocs?: SingleDocResponseData[];
  db_document_type?: DocumentTypeEnum;
  onUploadComplete: (info: SingleDocResponseData) => void;
  onDeleteDocument?: (docId: string) => void;
  description?: string;
  showSearch?: boolean;
  file_types?: string[];
  validateAllDocumentTypesHaveBeenSetAndUpdateDB?: () => boolean;
  availableDocumentTypes?: DocumentTypeEnum[];
  allowedFileFormats?: FileFormats;
  maxFiles?: number;
  onValidationChange?: (validationType: string, isValid: boolean) => void;
  validateFiles?: () => void;
}

const UploadArea = ({
  db_document_type = DocumentTypeEnum.Reference,
  onUploadComplete,
  onDeleteDocument,
  description = "PDF format only",
  showSearch = false,
  availableDocumentTypes = Object.values(RESTRICTED_DOCUMENT_TYPES),
  uploadedDocs,
  allowedFileFormats = DEFAULT_FILE_FORMATS,
  maxFiles = 30,
  onValidationChange,
  validateFiles,
}: UploadAreaProps) => {
  const [fileCount, setFileCount] = useState<number>(0);
  const [completedFiles, setCompletedFiles] = useState<UploadedFile[]>([]);
  const queryClient = useQueryClient();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState<UploadedFile | null>(null);

  useEffect(() => {
    if (uploadedDocs && uploadedDocs?.length > 0) {
      const newCompletedFiles: UploadedFile[] = uploadedDocs.map((doc) => ({
        filename: doc.document.filename,
        status: "accepted",
        ui_document_type: doc.document.documentType as DocumentTypeEnum,
        docResponse: doc,
      }));

      const updatedFiles = newCompletedFiles.map((newFile) => {
        const existingFile = completedFiles.find(
          (f) => f.filename === newFile.filename
        );
        if (existingFile) {
          return {
            ...newFile,
            ui_document_type:
              existingFile.ui_document_type || newFile.ui_document_type,
          };
        }
        return newFile;
      });

      setCompletedFiles(updatedFiles);
    }
  }, [uploadedDocs]);

  // Update useEffect to use passed in validation
  useEffect(() => {
    validateFiles?.();
  }, [completedFiles]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: allowedFileFormats,
    maxFiles,
    onDrop: async (acceptedFiles, fileRejections) => {
      // Track seen filenames to identify duplicates
      const seenFilenames = new Set<string>();
      const duplicateFiles: File[] = [];

      // Filter out duplicates, keeping only the first occurrence
      acceptedFiles = acceptedFiles.filter((file) => {
        // Check if it's a duplicate of an existing file
        const isDuplicateOfExisting = completedFiles.some(
          (existingFile) => existingFile.filename === file.name
        );

        // Check if we've seen this filename in the current batch
        const isDuplicateInBatch = seenFilenames.has(file.name);

        if (isDuplicateOfExisting || isDuplicateInBatch) {
          duplicateFiles.push(file);
          return false;
        }

        seenFilenames.add(file.name);
        return true;
      });

      // Add duplicates to rejections
      if (duplicateFiles.length > 0) {
        fileRejections = [
          ...fileRejections,
          ...duplicateFiles.map((file) => ({
            file,
            errors: [
              {
                message: "This file has already been uploaded",
                code: "duplicate-file",
              },
            ],
          })),
        ];
      }

      const totalFiles =
        completedFiles.length + acceptedFiles.length + fileRejections.length;
      if (totalFiles > maxFiles) {
        const excessFiles = acceptedFiles.slice(
          maxFiles - completedFiles.length
        );
        fileRejections = [
          ...fileRejections,
          ...excessFiles.map((file) => ({
            file,
            errors: [
              {
                message: `Maximum ${maxFiles} files allowed`,
                code: "too-many-files",
              },
            ],
          })),
        ];
        acceptedFiles = acceptedFiles.slice(
          0,
          maxFiles - completedFiles.length
        );
      }

      setFileCount((old) => old + acceptedFiles.length + fileRejections.length);

      // Process accepted files
      for (const file of acceptedFiles) {
        if (file.size === 0) {
          setCompletedFiles((old) => [
            {
              filename: file.name,
              status: "rejected",
              message:
                "File did not upload correctly. If you are using ImageRight or another document provider, please make sure you save it locally on your computer before uploading.",
              ui_document_type: db_document_type as DocumentTypeEnum,
              docResponse: {} as SingleDocResponseData,
            },
            ...old,
          ]);
          continue;
        }

        if (file.size > MAX_FILE_SIZE) {
          setCompletedFiles((old) => [
            {
              filename: file.name,
              status: "rejected",
              message: "File size exceeds the maximum limit of 150 MB.",
              ui_document_type: db_document_type as DocumentTypeEnum,
              docResponse: {} as SingleDocResponseData,
            },
            ...old,
          ]);
          continue;
        }

        try {
          const fileHash = await generateHash(file);
          const docResponse: SingleDocResponseData = await createDocument({
            filename: file.name,
            document_type: db_document_type,
            file_hash: fileHash,
          });

          // Match the working implementation from UCPolicyChat.tsx

          if (!docResponse.existingDoc) {
            const uploadResponse = await fetch(docResponse.presignedUrl, {
              method: "PUT",
              headers: {
                "Content-Type": file.type,
              },
              body: file,
            });

            if (!uploadResponse.ok) {
              const errorText = await uploadResponse.text();
              console.error("Upload failed:", {
                status: uploadResponse.status,
                statusText: uploadResponse.statusText,
                errorText,
                presignedUrl: docResponse.presignedUrl,
              });
              throw new Error(
                `Upload failed with status: ${uploadResponse.status} - ${errorText}`
              );
            }
          }

          queryClient.invalidateQueries("referenceDocuments");

          setCompletedFiles((old) => [
            {
              filename: file.name,
              status: "accepted",
              ui_document_type: db_document_type,
              docResponse: {
                ...docResponse,
                document: {
                  ...docResponse.document,
                  documentType: db_document_type,
                },
              },
            },
            ...old,
          ]);

          if (onUploadComplete) {
            onUploadComplete({
              ...docResponse,
              document: {
                ...docResponse.document,
                documentType: db_document_type,
              },
            });
          }
        } catch (error) {
          console.error("Upload error details:", error);
          setCompletedFiles((old) => [
            {
              filename: file.name,
              status: "rejected",
              message: `Upload failed: ${(error as Error).message}`,
              ui_document_type: db_document_type as DocumentTypeEnum,
              docResponse: {} as SingleDocResponseData,
            },
            ...old,
          ]);
        }
      }

      // Process rejected files
      fileRejections.forEach(({ file, errors }) => {
        setCompletedFiles((old) => [
          {
            filename: file.name,
            status: "rejected",
            message: errors.map((e) => e.message).join(", "),
            ui_document_type: db_document_type as DocumentTypeEnum,
            docResponse: {} as SingleDocResponseData,
          },
          ...old,
        ]);
      });

      // After all files are processed, validate
      validateFiles?.();
    },
  });

  const isUploading = fileCount > 0 && fileCount > completedFiles.length;

  const handleUploudFileFromList = (docResponse: SingleDocResponseData) => {
    setCompletedFiles((old) => [
      {
        filename: docResponse.document.filename,
        status: "accepted",
        ui_document_type: docResponse.document.documentType || db_document_type,
        docResponse: docResponse,
      },
      ...old,
    ]);

    if (onUploadComplete) {
      onUploadComplete({
        ...docResponse,
        document: {
          ...docResponse.document,
          documentType: db_document_type,
        },
      });
    }
  };

  const handleCategoryChange = async (
    filename: string,
    newDocumentType: DocumentTypeEnum
  ) => {
    const fileToUpdate = completedFiles.find(
      (file) => file.filename === filename
    );
    if (fileToUpdate) {
      try {
        await updateDocumentType([
          {
            document_id: fileToUpdate.docResponse.document.id,
            document_type: newDocumentType,
          },
        ]);

        // Update local state
        setCompletedFiles((old) =>
          old.map((file) =>
            file.filename === filename
              ? {
                  ...file,
                  ui_document_type: newDocumentType,
                  docResponse: {
                    ...file.docResponse,
                    document: {
                      ...file.docResponse.document,
                      documentType: newDocumentType,
                    },
                  },
                }
              : file
          )
        );

        // Invalidate the query cache to ensure fresh data
        queryClient.invalidateQueries("referenceDocuments");

        // Update parent state with the complete updated document
        if (onUploadComplete) {
          const updatedDoc = {
            ...fileToUpdate.docResponse,
            document: {
              ...fileToUpdate.docResponse.document,
              documentType: newDocumentType,
            },
          };
          onUploadComplete(updatedDoc);
        }

        // Trigger validation update
        validateFiles?.();

        // Notify parent of type selection status
        onValidationChange?.("typesSelected", true);

        // Show success toast
        toast.success("Document type updated successfully");
      } catch (error) {
        console.error("Error updating document type:", error);
        toast.error("Failed to update document type. Please try again.");
      }
    }
  };

  const handleDeleteFile = async () => {
    if (fileToDelete) {
      const fileToDeleteObj = completedFiles.find(
        (file) => file.filename === fileToDelete.filename
      );
      if (fileToDeleteObj) {
        // Only call onDeleteDocument if the file was successfully uploaded (has a valid docResponse)
        if (
          onDeleteDocument &&
          fileToDeleteObj.status === "accepted" &&
          fileToDeleteObj.docResponse?.document?.id
        ) {
          await onDeleteDocument(fileToDeleteObj.docResponse.document.id);
        }

        // Update the completedFiles state to remove the deleted file
        setCompletedFiles((old) => {
          const updatedFiles = old.filter(
            (file) => file.filename !== fileToDeleteObj.filename
          );
          setFileCount(updatedFiles.length);
          return updatedFiles;
        });

        // Validate after deletion
        validateFiles?.();
      }
      setFileToDelete(null);
    }
    setIsDialogOpen(false);
  };

  return (
    <div className="rounded-lg border border-gray-300">
      {showSearch && (
        <JobReportFileSearch
          file_types={availableDocumentTypes}
          itemsPerPage={10}
          uploudFileFromList={handleUploudFileFromList}
        />
      )}
      <div {...getRootProps()}>
        <div
          className={`border-dashed p-8 text-center ${
            isDragActive
              ? "border-palette-brand-green/80 bg-palette-brand-green/5"
              : "border-gray-200"
          }`}
        >
          <input {...getInputProps()} />
          {isUploading ? (
            <>
              <div>Uploading...</div>
              <Progress
                className="mt-2"
                value={100 * (completedFiles.length / fileCount)}
              />
            </>
          ) : (
            <>
              <span className="cursor-pointer text-primary underline underline-offset-4 hover:text-primary/90">
                Click here
              </span>{" "}
              or drag a file to upload.
              <div className="mt-2 text-xs text-gray-400">{description}</div>
            </>
          )}
        </div>
      </div>
      {completedFiles.length > 0 && (
        <div className="mt-8 space-y-4 px-4 pb-4">
          {completedFiles.map((file, index) => (
            <FileListItem
              key={`${index}_${file.filename}`}
              file={file}
              onCategoryChange={handleCategoryChange}
              onDeleteClick={(file) => {
                setFileToDelete(file);
                setIsDialogOpen(true);
              }}
              availableDocumentTypes={availableDocumentTypes}
            />
          ))}
        </div>
      )}
      {isDialogOpen && (
        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Confirm Deletion</DialogTitle>
              <DialogDescription>
                Are you sure you want to remove the file "
                {fileToDelete?.filename}"?
              </DialogDescription>
            </DialogHeader>
            <DialogFooter>
              <Button
                onClick={() => setIsDialogOpen(false)}
                className="border border-black bg-white text-black hover:bg-black hover:text-white"
              >
                Cancel
              </Button>
              <Button
                onClick={handleDeleteFile}
                className="border border-black bg-white text-black hover:bg-black hover:text-white"
              >
                Delete
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default UploadArea;
